<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getUsers"
  >
    <template #title> Users List </template>
    <template #filters>
      <search-input
        @enter="getUsers"
        v-model="usersListFilters.search"
        inputId="SearchFilter"
        inputLabel="Search By User"
        inputPlaceholder="s y"
      ></search-input>
      <b-form-group label="Count" label-for="count">
        <v-select
          id="count"
          v-model="usersListFilters.count"
          :options="perPageOptions"
          :clearable="false"
        />
      </b-form-group>
    </template>
    <custom-table
      v-if="users"
      :pageNumber="usersListFilters.pageNumber"
      :count="usersListFilters.count"
      :totalCount="totalCount"
      :tableItems="users"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #name="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="45"
              :src="baseURL + data.item.selfieFileData"
              :variant="`success`"
              :to="{
                name: 'apps-users-edit',
                params: { id: data.item.userId },
              }"
            />
          </template>
          <b-link
            :to="{
              name: 'apps-users-edit',
              params: { id: data.item.userId },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }} {{ data.item.familyName }}
          </b-link>
          <small class="text-muted">@{{ data.item.userId }}</small>
        </b-media>
      </template>
      <template #edit="data">
        <b-link
          :to="{
            name: 'apps-users-edit',
            params: { id: data.item.userId },
          }"
        >
          <feather-icon icon="EditIcon" size="20" />
        </b-link>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import { BMedia, BAvatar, BLink, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { UsersGetAllRequest } from "@/libs/Api/Users";
import Helper from "@/libs/Helper";

export default {
  title: "Users List",
  data() {
    return {
      isLoading: false,
      users: null,
      baseURL: Helper.baseUrl,
      usersListFilters: {
        pageNumber: 1,
        count: 10,
        search: null,
        perState: null,
      },
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        { key: "name", label: "User" },
        { key: "userName", label: "User Name" },
        { key: "email", label: "email" },
        { key: "edit", label: "Edit" },
      ],
    };
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    changePage(pageNumber) {
      this.usersListFilters.pageNumber = pageNumber;
      this.getUsers();
    },
    refreshData() {
      this.getUsers();
    },
    async getUsers() {
      try {
        this.isLoading = true;
        let _this = this;

        let usersGetAllRequest = new UsersGetAllRequest(_this);
        usersGetAllRequest.setParams(_this.usersListFilters);
        await usersGetAllRequest.fetch(
          function (content) {
            _this.users = content.users;
            _this.totalCount = content.usersCount;
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    count: function (nv, ov) {
      this.getUsers();
    },
  },
  computed: {
    count() {
      return this.usersListFilters.count;
    },
  },
  components: {
    BMedia,
    BAvatar,
    BLink,
    vSelect,
    PageWrapper,
    SearchInput,
    BFormGroup,
    CustomTable,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
