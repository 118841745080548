var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-wrapper',{attrs:{"isLoading":_vm.isLoading,"hasFilters":true},on:{"submitFilters":_vm.getUsers},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Users List ")]},proxy:true},{key:"filters",fn:function(){return [_c('search-input',{attrs:{"inputId":"SearchFilter","inputLabel":"Search By User","inputPlaceholder":"s y"},on:{"enter":_vm.getUsers},model:{value:(_vm.usersListFilters.search),callback:function ($$v) {_vm.$set(_vm.usersListFilters, "search", $$v)},expression:"usersListFilters.search"}}),_c('b-form-group',{attrs:{"label":"Count","label-for":"count"}},[_c('v-select',{attrs:{"id":"count","options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.usersListFilters.count),callback:function ($$v) {_vm.$set(_vm.usersListFilters, "count", $$v)},expression:"usersListFilters.count"}})],1)]},proxy:true}])},[(_vm.users)?_c('custom-table',{attrs:{"pageNumber":_vm.usersListFilters.pageNumber,"count":_vm.usersListFilters.count,"totalCount":_vm.totalCount,"tableItems":_vm.users,"tableColumns":_vm.myTableColumns},on:{"changePage":_vm.changePage},scopedSlots:_vm._u([{key:"name",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"45","src":_vm.baseURL + data.item.selfieFileData,"variant":"success","to":{
              name: 'apps-users-edit',
              params: { id: data.item.userId },
            }}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
            name: 'apps-users-edit',
            params: { id: data.item.userId },
          }}},[_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.familyName)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(data.item.userId))])],1)]}},{key:"edit",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'apps-users-edit',
          params: { id: data.item.userId },
        }}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"20"}})],1)]}}],null,false,2462392477)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }